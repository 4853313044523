const ethUtil = require('ethereumjs-util') // For Encrypt Message
const sigUtil = require('eth-sig-util') // For Encrypt Message


export  function   doEncryp (plainText,callback, args = []) {
      // Get Account from MetaMask
      ethereum.request({ method: 'eth_requestAccounts' }).then((accounts) => {
        const account = accounts[0];

        // START Get encryptionPublicKey for Encrypt Messages
        let encryptionPublicKey;
        ethereum
          .request({
            method: 'eth_getEncryptionPublicKey',
            params: [account], // you must have access to the specified account
          })
          .then((result) => {
            encryptionPublicKey = result;
            // START Encrypt Message
            const encryptedMessage = ethUtil.bufferToHex(
              Buffer.from(
                JSON.stringify(
                  sigUtil.encrypt(
                    encryptionPublicKey,
                    { data: plainText },
                    'x25519-xsalsa20-poly1305'
                  )
                ),
                'utf8'
              )
            );
            callback (encryptedMessage, ...args) 
            //this.$store.commit(store_mutation, encryptedMessage)
          })
          .catch((error) => {
            if (error.code === 4001) {
              // EIP-1193 userRejectedRequest error
              console.log("We can't encrypt anything without the key.");
            } else {
              console.error(error);
            }
          });
          // END Get encryptionPublicKey for Encrypt Messages
      })

  };


export  function  doDecryp(cipherText,callback, args = []) {
  // Get Account from MetaMask
      ethereum.request({ method: 'eth_requestAccounts' }).then((accounts) => {
        const account = accounts[0];
            const encryptedData = cipherText
            // START Decrypt messsage
            ethereum
              .request({
                method: 'eth_decrypt',
                params: [encryptedData, account],
              })
              .then((decryptedMessage) =>
                callback (decryptedMessage, ...args ) ,
                //console.log('The decrypted message is:', decryptedMessage)
              )
              .catch((error) => console.log(error.message));
          })
          .catch((error) => {
            if (error.code === 4001) {
              // EIP-1193 userRejectedRequest error
              console.log("We can't encrypt anything without the key.");
            } else {
              console.error(error);
            }
          });
  }