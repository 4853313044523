<template>
    <div >
    <h1>{{msg}}</h1>
    <v-select id="quote" :options="marketOptions" :searchable="false" :clearable="false" v-model="market" style="width: 100px"></v-select>
        <button
          class="btn btn-primary btn-margin"
          @click="querybalance()">
          Actualizar balances
        </button> 
        <CDataTable :items="crypto_assets" :fields="fields" sorter  column-filter hover pagination />
    </div>
</template>
<script>
import vSelect from 'vue-select'
import CTableWrapper from './Table.vue'
const ccxt = require ('ccxt')
import { mapState } from 'vuex'
import  {doDecryp} from '../../services/secureVault.js'
const exchange = new ccxt.binance()

const fields = [
  'asset','total','free','locked','market'
]

  export default {
    //import Binance from 'binance-api-node'
    name: 'allcoinsbalance',
      components: {
        CTableWrapper
      },
    data () {
      return {
        fields,
        msg: 'Pedir saldo de todas las monedas',
        //balance : [],
        market : '',
        // marketOptions: ['binance','uniswap','bitforex','bilaxy', 'gateio'],
        marketOptions: ['binance','bitforex', 'gateio','bitmax','kucoin'],
        kucoin_tmp_pass : ''
      }
    },
    components: {
      vSelect,
    },
    methods: {    
      get_kucoin_password (secret) {
        console.log(secret)
          this.kucoin_tmp_pass = secret
          doDecryp (this.kucoin_api_secret,this.get_balance,[this.kucoin_api_key,'kucoin']) 

      },
      get_balance (secret,apikey,market) {
       let _this = this
       this.fetchBalance(apikey,secret,market).then(function(value) {
            var text = JSON.stringify(value);
            var parsedtext = JSON.parse(text);
            var balances_positivos = []
            if(market=='binance') {
              var balances = parsedtext.info.balances;
              balances.forEach( function(valor, indice, array) {
                  if ((valor.free > 0)||(valor.locked > 0) ) {
                    valor.market = market
                    valor.total = valor.free + valor.locked
                    balances_positivos.push(valor)
                  }                    
                  // console.log("En el índice " + indice + " hay este valor: " + valor);
                });

            } else if (market=='bitforex'){
              var balances = parsedtext.info.data;
              balances.forEach( function(valor, indice, array) {
                  if ((valor.active > 0)||(valor.fix > 0)||(valor.frozen > 0) ) {
                    var tmp_valor = {}
                    tmp_valor.market = market
                    tmp_valor.free = valor.active
                    tmp_valor.locked = valor.frozen
                    tmp_valor.asset = valor.currency
                    tmp_valor.total = Number(valor.active) + Number(valor.frozen)
                    balances_positivos.push(tmp_valor)
                  }
                  // console.log("En el índice " + indice + " hay este valor: " + valor);
                }); 

            }  else if (market=='bitmax'){
              var balances = parsedtext.info.data;
              balances.forEach( function(valor, indice, array) {
                    var tmp_valor = {}
                    tmp_valor.market = market
                    tmp_valor.free = valor.availableBalance
                    tmp_valor.locked = valor.totalBalance-valor.availableBalance
                    tmp_valor.asset = valor.asset
                    tmp_valor.total = valor.totalBalance
                    balances_positivos.push(tmp_valor)
                  // console.log("En el índice " + indice + " hay este valor: " + valor);
                }); 
            } else if (market=='gateio'){
              var balances = parsedtext.info.available;
              var balances_locked = parsedtext.info.locked;
              console.log(balances)
              console.log(balances_locked)
              for( var key in balances ) {
                console.log(balances[key]);
                var tmp_valor = {}
                tmp_valor.market = market
                tmp_valor.free = balances[key]
                tmp_valor.locked = balances_locked[key]
                tmp_valor.asset = key
                tmp_valor.total = Number(balances[key])+Number(balances_locked[key])
                balances_positivos.push(tmp_valor)
              }
            }  else if (market=='kucoin'){
              var balances = parsedtext.info.data;
              balances.forEach( function(valor, indice, array) {
                    var tmp_valor = {}
                    console.log(valor)
                    tmp_valor.market = market
                    tmp_valor.free = valor.available
                    tmp_valor.locked = Number(valor.balance)-Number(valor.available)
                    tmp_valor.asset = valor.currency
                    tmp_valor.total = valor.balance
                    balances_positivos.push(tmp_valor)
                  // console.log("En el índice " + indice + " hay este valor: " + valor);
                }); 
            }        
          _this.$store.commit('updateCryptoMarketAssets',balances_positivos)
          }, function(reason) {
          // rechazo
        });
     },
      async fetchBalance(apiKey,secret,market) {
          try {
              const currentEx = new ccxt[market]()
              currentEx.apiKey = apiKey
              currentEx.secret =  secret
              if (market == 'kucoin') {
                  currentEx.password =  this.kucoin_tmp_pass
              }
              currentEx.enableRateLimit = true
              currentEx.proxy ='https://maniacmansion.org/RGBanticors/'
              return await  currentEx.fetchBalance ()
          } catch (error) {
              console.log(error)
          }
      },
      querybalance () {
        if(this.market=='binance'){
          doDecryp (this.binance_api_secret,this.get_balance,[this.binance_api_key,'binance']) 
        } else if (this.market=='bitforex'){
          doDecryp (this.bitforex_api_secret,this.get_balance,[this.bitforex_api_key,'bitforex']) 
        } else if (this.market=='gateio'){
          doDecryp (this.gateio_api_secret,this.get_balance,[this.gateio_api_key,'gateio']) 
        } else if (this.market=='bitmax'){
          doDecryp (this.bitmax_api_secret,this.get_balance,[this.bitmax_api_key,'bitmax']) 
        }else if (this.market=='kucoin'){
          doDecryp (this.kucoin_api_password,this.get_kucoin_password) 
        }
        
      },
    },
    mounted () {
     //this.balance = JSON.parse(this.crypto_assets)
    //  this.balance = this.crypto_assets
    },
    computed: {
      ...mapState(['binance_api_key', 'binance_api_secret','kucoin_api_key','kucoin_api_secret','kucoin_api_password','bitmax_api_key', 'bitmax_api_secret', 'gateio_api_key', 'gateio_api_secret', 'crypto_assets','bitforex_api_key', 'bitforex_api_secret','timer_binance_getallOrders'])
    }
} 


</script>
